<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addMerchant'})">المتاجر  الغير مفعلة</dashboard-page-title>
    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK"  :fields="fields" list_url="admin/stores" :additional-url="`&status=processing`" :reloadData="reloadData"></main-table>

    <b-modal id="suspendedModal" size="lg" title="سبب ايقاف المتجر" style="direction: rtl" centered hide-footer hide-header-close>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row class="d-flex flex-wrap">
          <b-col>
            <textarea-form placeholder="السبب" v-model="reason" label="سبب مختصر" name="reason" validate="required"></textarea-form>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

      <div class="d-flex justify-content-center mt-2">
        <b-button v-if='saveButton' class="btn btn-primary float-right" variant="primary" disabled>
          <spinner-loading text = 'Loading' />
        </b-button>
        <b-button variant="primary" v-else class="w-50" @click="handleSuspendMarket">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable*/ 
import { core } from '@/config/pluginInit'
import marketPlaceServices from '../services/marketPlace'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_MARKETPLACE_LINK,
      reloadData: false,
      fields: [
        { label: 'صوره الشعار', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'صوره الغلاف', key: 'cover', type: 'image', class: 'text-right' },
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' },
        {
          label: 'تفعيل',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              icon: 'ri-eye-fill',
              color: 'success-light',
              text: 'تفعيل',
              ifNavigate: true,
              routePath: 'show-inactive-marketplace/:id'
            },
            {
              icon: 'ri-ball-pen-fill',
              color: 'info',
              text: 'تفعيل',
              ifNavigate: true,
              routePath: 'edit-marketplace/:id'
            },
            {
              text: 'suspend',
              icon: 'ri-spam-2-fill',
              color: 'danger',
              actionParams: ['id'],
              actionName: 'showSuspensedModal'
            }
          ]
        }
      ],
      reloadTable: false
    }
  },
  methods: {
    showSuspensedModal (data) {
      this.suspendedId = data.id
      this.$bvModal.show('suspendedModal')
    },
    handleSuspendMarket () {
      this.saveButton = true
      marketPlaceServices
        .marketStatus(this.suspendedId, 'rejected', { mail_body: this.reason })
        .then((res) => {
          core.showSnackbar('success', 'تم  ايقاف المتجر بنجاح')
          this.saveButton = false
          this.$bvModal.hide('suspendedModal')
          this.reloadData = true
      })
      this.reloadData = false
    }
  },
  created () {
    this.$root.$on('showSuspensedModal', this.showSuspensedModal)
  }
}
</script>
